import React from "react";
import generateAttributes from "../../functions/generateAttributes";
import "../../styles/components/Divider.scss";

function Divider(props) {
    const attr = generateAttributes("Divider", props);

    return <div {...attr()} />;
}

export default Divider;
