import React from "react";
import { Link } from "react-router-dom";
import generateAttributes from "../../functions/generateAttributes";
import "../../styles/components/Button.scss";
import Icon from "./Icon.js";
import { ReactComponent as ChevronRightSvg } from "../../assets/icons/chevronRight.svg";

function Button(props) {
    // Determine the root element type
    let ElementType, passedProps;
    if (props?.type === undefined || props?.type === "button") {
        ElementType = "button";
        passedProps = {
            onClick: (e) => {
                e.preventDefault(); // Prevents zooming behaviour in Safari iOS
                if (props.onClick) {
                    props.onClick(e);
                }
            },
        };
    } else if (props.type === "submit") {
        ElementType = "button";
        passedProps = {
            onClick: (e) => {
                if (props.onClick) {
                    props.onClick(e);
                }
            },
            type: "submit",
        };
    } else if (props?.type === "externalLink") {
        ElementType = "a";
        passedProps = {
            href: props.href,
        };
    } else if (props?.type === "link") {
        passedProps = {
            to: props.to,
        };
    }

    const attr = generateAttributes("Button", props, {
        overContent: props.overContent,
    });

    const SwitchWrap = ({
        condition,
        wrapperIfTrue,
        wrapperIfFalse,
        children,
    }) => (condition ? wrapperIfTrue(children) : wrapperIfFalse(children));

    return (
        <SwitchWrap
            condition={props.type === "link"}
            wrapperIfTrue={(children) => (
                <Link
                    {...attr()}
                    {...passedProps}
                    onMouseDown={(e) => e.preventDefault()} // Prevents focus styles on click
                    tabIndex={props.focusable === false ? "-1" : ""}
                >
                    {children}
                </Link>
            )}
            wrapperIfFalse={(children) => (
                <ElementType
                    {...attr()}
                    {...passedProps}
                    onMouseDown={(e) => e.preventDefault()} // Prevents focus styles on click
                    tabIndex={props.focusable === false ? "-1" : ""}
                >
                    {children}
                </ElementType>
            )}
        >
            {props.variations.includes("type:text") && props?.text}
            {props.variations.includes("withChevron") && (
                <Icon tags="chevron" svg={ChevronRightSvg} />
            )}

            {props.variations.includes("type:icon") && (
                <div {...attr("iconWrapper")}>{props.icon}</div>
            )}
            {props.hiddenLabel && (
                <span {...attr("hiddenLabel")}>{props.hiddenLabel}</span>
            )}
        </SwitchWrap>
    );
}

export default Button;
