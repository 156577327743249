// Polyfills
import "intersection-observer";
import smoothscroll from "smoothscroll-polyfill";

import React from "react";
import ReactDOM from "react-dom";
import { BrowserRouter as Router } from "react-router-dom";
import * as serviceWorker from "./serviceWorker";

import App from "./react/App.js";
import ScrollToTop from "./react/components/ScrollToTop.js";
import "./styles/global/reset.scss";
import "./styles/global/theme.scss";

ReactDOM.render(
    <React.StrictMode>
        <Router>
            <ScrollToTop />
            <App />
        </Router>
    </React.StrictMode>,
    document.getElementById("root")
);
smoothscroll.polyfill();

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
