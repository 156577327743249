import React, { useState, useEffect } from "react";
import generateAttributes from "../../../functions/generateAttributes";
import { Link, useLocation } from "react-router-dom";
import "../../../styles/components/Menu/Menu.scss";
import MenuItem from "./MenuItem.js";
import Button from "./../../components/Button.js";
import MenuButton from "./MenuButton";

function Menu(props) {
    const [open, setOpen] = useState(false);
    const [onDesktop, setOnDesktop] = useState(false);
    const [topOfPage, setTopOfPage] = useState(false);

    // Close the Menu when path changes
    const location = useLocation();
    useEffect(() => {
        setOpen(false);
    }, [location]);

    // Setup a mediaQuery listener to set onDesktop and close
    // the menu on mobile
    useEffect(() => {
        if (matchMedia) {
            const mqDesktop = window.matchMedia("(min-width: 800px)");
            if (mqDesktop.matches) {
                setOnDesktop(true);
            }
            mqDesktop.addListener(() => {
                if (mqDesktop.matches) {
                    setOnDesktop(true);
                    setOpen(false);
                }
            });
            const mqMobile = window.matchMedia("(max-width: 799px)");
            if (mqMobile.matches) {
                setOnDesktop(false);
            }
            mqMobile.addListener(() => {
                if (mqMobile.matches) {
                    setOnDesktop(false);
                }
            });
        }
    }, []);

    // Setup a scroll listener to switch topOfPage
    useEffect(() => {
        const checkScrollPositionAndSetTopOfPageAccordingly = () => {
            setTopOfPage(window.scrollY <= 0);
        };
        if (props.variations?.includes("overContent")) {
            checkScrollPositionAndSetTopOfPageAccordingly();
            window.addEventListener(
                "scroll",
                checkScrollPositionAndSetTopOfPageAccordingly
            );
        } else {
            setTopOfPage(false);
        }
        return () => {
            window.removeEventListener(
                "scroll",
                checkScrollPositionAndSetTopOfPageAccordingly
            );
        };
    }, [props.variations]);

    const attr = generateAttributes("Menu", props, {
        open,
        onDesktop,
        topOfPage,
    });

    return (
        <nav {...attr()}>
            <div
                {...attr("overlay")}
                onClick={(e) => {
                    e.preventDefault(); // Prevents zooming behaviour in Safari iOS
                    setOpen(!open);
                }}
            >
                <span data-visually-hidden>Close the menu</span>
            </div>
            <div {...attr("header")}>
                <Link
                    className="#logo"
                    to="/"
                    onMouseDown={(e) => e.preventDefault()} // Prevents focus styles on click
                >
                    Ciara<span {...attr("logoBy")}> by Carbone 4</span>
                </Link>
                <MenuButton
                    setOpen={setOpen}
                    focusable={!onDesktop}
                    open={open}
                    overContent={
                        props.variations === "overContent" && !open && topOfPage
                    }
                />
            </div>
            <ul {...attr("items")}>
                <li {...attr("item")}>
                    <MenuItem
                        to="/"
                        text="What we offer"
                        menuOpen={open}
                        menuTopOfPage={topOfPage}
                        focusable={onDesktop || open}
                    />
                </li>
                <li {...attr("item")}>
                    <MenuItem
                        to="/about"
                        text="Who we are"
                        menuOpen={open}
                        menuTopOfPage={topOfPage}
                        focusable={onDesktop || open}
                    />
                </li>
                <li {...attr("item #mobileButton")}>
                    <Button
                        variations="type:text withChevron size:big"
                        type="link"
                        to="/contact"
                        text="Contact us"
                        focusable={onDesktop || open}
                    />
                </li>
                <li {...attr("item #desktopButton")}>
                    <Button
                        variations="type:text"
                        overContent={topOfPage}
                        type="link"
                        to="/contact"
                        text="Contact us"
                        focusable={onDesktop || open}
                    />
                </li>
            </ul>
        </nav>
    );
}

export default Menu;
