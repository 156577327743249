import React, { useRef, useEffect, useState } from "react";
import generateAttributes from "../../../functions/generateAttributes";
import "../../../styles/pages/Home/TabNav.scss";
import Tab from "./Tab.js";

function TabNav(props) {
    const attr = generateAttributes("TabNav", props);

    // Get the offset of all tabs inside the list
    const listElement = useRef(null);
    const buttonsOffsets = useRef([]);
    useEffect(() => {
        // Timeout necessary for the font to load and the dimensions
        // to be correct
        const timeout = setTimeout(() => {
            const listElementRect = listElement.current.getBoundingClientRect();
            props.tabsRef.forEach((tab) => {
                const tabRect = tab.current.getBoundingClientRect();
                const offsets = {
                    left: tabRect.left - listElementRect.left,
                    right: listElementRect.right - tabRect.right,
                };
                buttonsOffsets.current.push(offsets);
            });
        }, 1000);

        return () => {
            clearTimeout(timeout);
        };
    }, [props.tabsRef]);

    // Set the necessary inset values for the selected tab to be clipped
    // Magic numbers: initial values for first tab selected with current layout
    const [insetLeft, setInsetLeft] = useState(8);
    const [insetRight, setInsetRight] = useState(460.953125);
    const firstRender = useRef(true);
    useEffect(() => {
        // Timeout necessary for the font to load and the dimensions
        // to be correct
        const delay = firstRender.current ? 1050 : 0;
        firstRender.current = false;
        const timeout = setTimeout(() => {
            setInsetLeft(buttonsOffsets.current[props.selectedTab]?.left);
            setInsetRight(buttonsOffsets.current[props.selectedTab]?.right);
        }, delay);

        return () => {
            clearTimeout(timeout);
        };
    }, [props.selectedTab]);

    return (
        <div {...attr()}>
            <ul {...attr("list")} ref={listElement}>
                {props.tabs.map((tab, i) => (
                    <Tab
                        ref={props.tabsRef[i]}
                        key={tab.id}
                        tabId={tab.id}
                        tabName={tab.text}
                        selected={props.selectedTab === tab.id}
                        setSelectedTab={props.setSelectedTab}
                    />
                ))}
            </ul>
            <ul
                {...attr("clippedList")}
                style={{
                    "--inset-left": insetLeft,
                    "--inset-right": insetRight,
                }}
                aria-hidden="true" // Removes this duplicated element from accessibility tree
            >
                {props.tabs.map((tab, i) => (
                    <Tab
                        variations="inClipped"
                        key={tab.id}
                        tabId={tab.id}
                        tabName={tab.text}
                        selected={props.selectedTab === tab.id}
                        setSelectedTab={props.setSelectedTab}
                    />
                ))}
            </ul>
        </div>
    );
}

export default TabNav;
