import React, { useState } from "react";
import generateAttributes from "../../../functions/generateAttributes";
import "../../../styles/pages/Home/MetricsSection.scss";
import MetricCard from "./MetricCard.js";
import Button from "../../components/Button.js";
import DotsPattern from "../../components/DotsPattern.js";

function MetricsSection(props) {
    // State used on mobile to set all cards to visible once the first one is
    const [firstElementVisible, setFirstElementVisible] = useState(false);
    const attr = generateAttributes("MetricsSection", props);

    const climateCards = (
        <>
            {props.metricCards.climate.map((metricCard, index) => (
                <MetricCard
                    tags={`climate ${index + 1}`}
                    data={metricCard}
                    key={metricCard.title}
                    setFirstElementVisible={
                        index === 0 ? setFirstElementVisible : null
                    }
                    firstElementVisible={firstElementVisible}
                />
            ))}
        </>
    );

    const businessCards = (
        <>
            {props.metricCards.business.map((metricCard, index) => (
                <MetricCard
                    tags={`business ${index + 1}`}
                    data={metricCard}
                    key={metricCard.title}
                    firstElementVisible={firstElementVisible}
                />
            ))}
        </>
    );

    return (
        <section {...attr()}>
            <section {...attr("visibleSection")}>
                <DotsPattern />
                <div {...attr("visibleSectionInnerGrid")}>
                    <section {...attr("textualContent")}>
                        <div {...attr("supTitle")}>Precise data</div>
                        <h2 {...attr("title")}>The metrics we provide</h2>
                        <section {...attr("content")}>
                            <p {...attr("paragraph")}>
                                Our metrics are designed to build strong climate
                                leadership and to comply with market best
                                practices. They cover the full scope of your
                                strategy, from climate impact to business
                                resilience.
                            </p>
                            <section {...attr("metrics #mobile")}>
                                <section {...attr("cardGroupWrapper #climate")}>
                                    <section {...attr("cardGroup #climate")}>
                                        <header {...attr("cardGroupHeader")}>
                                            Climate Impact
                                        </header>
                                    </section>
                                    {climateCards}
                                </section>
                                <section
                                    {...attr("cardGroupWrapper #business")}
                                >
                                    <section {...attr("cardGroup #business")}>
                                        <header {...attr("cardGroupHeader")}>
                                            Business Resilience
                                        </header>
                                    </section>
                                    {businessCards}
                                </section>
                            </section>
                            <p {...attr("paragraph")}>
                                From now on, value creation will go along with
                                climate performance and reduced exposure to
                                climate-related risks: carbon metrics is another
                                word for competitive advantage.
                            </p>
                        </section>
                        <Button
                            variations="type:text size:small withChevron"
                            type="link"
                            to="/contact"
                            text="Ask for more information"
                            hiddenLabel="Go to contact page"
                        />
                    </section>
                </div>
            </section>
            <section {...attr("metrics #desktop")}>
                <div {...attr("metricsInnerGrid")}>
                    <div {...attr("cardGroups")}>
                        <section {...attr("cardGroup")}>
                            <header {...attr("cardGroupHeader")}>
                                Climate Impact
                            </header>
                            <section {...attr("cards")}>{climateCards}</section>
                        </section>
                        <section {...attr("cardGroup")}>
                            <header {...attr("cardGroupHeader")}>
                                Business Resilience
                            </header>
                            <section {...attr("cards")}>
                                {businessCards}
                            </section>
                        </section>
                    </div>
                </div>
            </section>
        </section>
    );
}

export default MetricsSection;
