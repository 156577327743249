import React, { useRef, useState, useEffect } from "react";
import generateAttributes from "../../../functions/generateAttributes";
import "../../../styles/pages/Home/MetricCard.scss";

const MetricCard = (props) => {
    const rootElement = useRef(null);
    const [visible, setVisible] = useState(false);
    useEffect(() => {
        const rootEl = rootElement.current;
        function callback(entries) {
            for (const entry of entries) {
                if (entry.isIntersecting) {
                    setVisible(true);
                    // Only call setFirstElementVisible if it is passed
                    // i.e. if this is the first MetricCard
                    if (props.setFirstElementVisible) {
                        props.setFirstElementVisible();
                    }
                }
            }
        }

        let myIntersectionObserver;
        // If we are on desktop or this is the first MetricCard
        if (
            window.matchMedia("(min-width: 900px)").matches ||
            props.setFirstElementVisible
        ) {
            // Set up an intersection observer for the rootElement
            myIntersectionObserver = new IntersectionObserver(callback, {
                root: null, // observe the viewport
                rootMargin: "0px 0px -12px 0px",
                threshold: 1,
            });
            myIntersectionObserver.observe(rootEl);
        }

        // Remove the intersection observer
        return () => {
            if (myIntersectionObserver) {
                myIntersectionObserver.unobserve(rootEl);
            }
        };
    }, [props]);

    const attr = generateAttributes("MetricCard", props, {
        // If we are on desktop, use the visible state, else use the firstElementVisible prop
        visible: window.matchMedia("(min-width: 900px)").matches
            ? visible
            : props.firstElementVisible,
    });

    return (
        <article {...attr()} ref={rootElement}>
            <header {...attr("header")}>
                <h3 {...attr("title")}>{props.data.title}</h3>
            </header>
            <section {...attr("content")}>
                <ul {...attr("contentList")}>
                    {props.data.content.map((item) => (
                        <li
                            {...attr("contentItem")}
                            key={item}
                            // Necessary to inject it like that to be able to
                            // style subscript
                            dangerouslySetInnerHTML={{ __html: item }}
                        />
                    ))}
                </ul>
            </section>
            <section {...attr("coverage")}>
                <header {...attr("covegageHeader")}>
                    <h4 {...attr("coverageTitle")}>Coverage</h4>
                </header>
                <section {...attr("coverageContent")}>
                    {props.data.coverage.map((item) => (
                        <div {...attr("coverageItem")} key={item}>
                            {item}
                        </div>
                    ))}
                </section>
            </section>
        </article>
    );
};

export default MetricCard;
