import React, { useRef, useState, useEffect } from "react";
import generateAttributes from "../../../functions/generateAttributes";
import "../../../styles/pages/Home/VisuSlider.scss";
import TabNav from "./TabNav.js";

import VisuPortfolioMobile from "../../../assets/images/illustrations/visu_portfolio_mobile.svg";
import VisuPortfolioDesktop from "../../../assets/images/illustrations/visu_portfolio_desktop.svg";
import VisuClimateMobile from "../../../assets/images/illustrations/visu_climate_mobile.svg";
import VisuClimateDesktop from "../../../assets/images/illustrations/visu_climate_desktop.svg";
import VisuEmissions from "../../../assets/images/illustrations/visu_emissions.svg";

const tabsContent = [
    {
        id: 0,
        text: "Portfolio T°C alignment metrics",
    },
    {
        id: 1,
        text: "Climate Dashboard",
    },
    {
        id: 2,
        text: "Induced & Avoided Emissions",
    },
];

function VisuSlider(props) {
    // Handle logic for tabs
    const scrollSection = useRef(null);
    tabsContent[0].ref = useRef(null);
    tabsContent[1].ref = useRef(null);
    tabsContent[2].ref = useRef(null);

    // Handle manual tab selection
    const [selectedTab, setSelectedTab] = useState(0);
    const manualSelection = useRef(false);
    const timeOut = useRef(null);
    function setSelectedTabManually(tabId) {
        clearTimeout(timeOut.current);
        manualSelection.current = true;
        setSelectedTab(tabId);
        const offsetLeft = tabsContent[tabId].ref.current.offsetLeft;
        const width = tabsContent[tabId].ref.current.offsetWidth;
        const tabContentCenter = offsetLeft + width / 2;

        scrollSection.current.scrollTo({
            left: tabContentCenter - window.innerWidth / 2,
            top: 0,
            behavior: "smooth",
        });
        timeOut.current = setTimeout(() => {
            manualSelection.current = false;
        }, 500);
    }

    // Handle scroll tab selection
    useEffect(() => {
        function callback(entries) {
            for (const entry of entries) {
                // Only execute if not manual
                if (entry.isIntersecting && !manualSelection.current) {
                    for (let i = 0; i < 3; i++) {
                        if (entry.target === tabsContent[i].ref.current) {
                            setSelectedTab(i);
                        }
                    }
                }
            }
        }

        // Set up intersection observers for all tab contents
        let myIntersectionObserver;
        for (let i = 0; i < 3; i++) {
            myIntersectionObserver = new IntersectionObserver(callback, {
                root: scrollSection.current,
                // ! Magic numbers based on current layout
                rootMargin: "0px -40% 0px -55%",
                threshold: 0.05,
            });
            myIntersectionObserver.observe(tabsContent[i].ref.current);
        }
        // Remove the intersection observers
        return () => {
            for (let i = 0; i < 3; i++) {
                myIntersectionObserver.unobserve(tabsContent[i].ref.current);
            }
        };
    }, []);

    // Handle scroll in tabNavWrapper
    const tabNavWrapper = useRef(null);
    const tabs = [];
    tabs[0] = useRef(null);
    tabs[1] = useRef(null);
    tabs[2] = useRef(null);
    const firstRender = useRef(true);
    useEffect(() => {
        if (firstRender.current) {
            firstRender.current = false;
        } else {
            const offsetLeft = tabs[selectedTab].current.offsetLeft;
            const width = tabs[selectedTab].current.offsetWidth;
            const tabCenter = offsetLeft + width / 2;

            tabNavWrapper.current.scrollTo({
                left: tabCenter - window.innerWidth / 2,
                top: 0,
                behavior: "smooth",
            });
        }
    }, [tabs, selectedTab]);

    // Handle visibility on scroll
    const [tabsContentVisible, setTabsContentVisible] = useState(false);
    useEffect(() => {
        function callback(entries) {
            for (const entry of entries) {
                if (entry.isIntersecting) {
                    setTabsContentVisible(true);
                }
            }
        }

        // Set up an intersection observer for the first tabContent
        const myIntersectionObserver = new IntersectionObserver(callback, {
            root: null, // observe the viewport
            rootMargin: "0px 0px 0px 0px",
            threshold: 0.8,
        });
        myIntersectionObserver.observe(tabsContent[0].ref.current);

        // Remove the intersection observers
        return () => {
            myIntersectionObserver.unobserve(tabsContent[0].ref.current);
        };
    }, []);

    const attr = generateAttributes("VisuSlider", props, {
        selectedTab,
        tabsContentVisible,
    });

    return (
        <>
            <div {...attr("tabNavWrapper")} ref={tabNavWrapper}>
                <TabNav
                    tabsRef={tabs}
                    tabs={tabsContent}
                    selectedTab={selectedTab}
                    setSelectedTab={setSelectedTabManually}
                />
            </div>
            <section {...attr("tabsContent")} ref={scrollSection}>
                <article {...attr("tabContent")} ref={tabsContent[0].ref}>
                    <h3 {...attr("tabTitle")}>
                        Asset’s Alignment vs 2°C Pathway
                    </h3>
                    <div {...attr("graphWrapper")}>
                        <picture>
                            <source
                                srcSet={VisuPortfolioDesktop}
                                type="image/svg+xml"
                                media="(min-width: 650px)"
                            />
                            <img
                                {...attr("graph #1")}
                                src={VisuPortfolioMobile}
                                alt="A graph representing asset's alignment vs 2°C pathway"
                                width="359"
                                height="319"
                                draggable="false"
                            />
                        </picture>
                    </div>
                </article>
                <article {...attr("tabContent #2")} ref={tabsContent[1].ref}>
                    <h3 {...attr("tabTitle")}>Climate Dashboard</h3>
                    <div {...attr("graphWrapper")}>
                        <picture>
                            <source
                                srcSet={VisuClimateDesktop}
                                type="image/svg+xml"
                                media="(min-width: 650px)"
                            />
                            <img
                                {...attr("graph #2")}
                                src={VisuClimateMobile}
                                alt="A climate dashboard"
                                width="285"
                                height="224"
                                draggable="false"
                            />
                        </picture>
                    </div>
                </article>
                <article {...attr("tabContent #last")} ref={tabsContent[2].ref}>
                    <h3 {...attr("tabTitle")}>Induced & Avoided Emissions</h3>
                    <div {...attr("graphWrapper")}>
                        <img
                            {...attr("graph #3")}
                            src={VisuEmissions}
                            alt="A graph representing induced and avoided emissions"
                            width="383"
                            height="357"
                            draggable="false"
                        />
                    </div>
                </article>
            </section>
        </>
    );
}

export default VisuSlider;
