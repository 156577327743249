import React, { useState } from "react";
import { Formik, Form } from "formik";
import generateAttributes from "../../functions/generateAttributes";
import "../../styles/pages/Contact.scss";
import TextInput from "../components/TextInput.js";
import Button from "../components/Button.js";
import Divider from "../components/Divider.js";
import DotsPattern from "../components/DotsPattern.js";

function Contact(props) {
    const formInitialValues = {
        firstname: "",
        lastname: "",
        emailAddress: "",
        company: "",
        message: "",
    };

    const validateForm = (values) => {
        const errors = {};

        // Check that all fields are filled
        Object.keys(values).forEach((key) => {
            if (!values[key]) {
                errors[key] = `A ${key} is required.`;
            }
        });

        // Check if email address is valid
        if (
            !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i.test(
                values.emailAddress
            )
        ) {
            errors.emailAddress = "The email address specified is invalid.";
        }

        return errors;
    };

    const [formResponse, setFormResponse] = useState(null);
    const submitForm = (values, { setSubmitting }) => {
        fetch(
            "https://europe-west2-ciara-website-mailserver.cloudfunctions.net/sendMail",
            {
                method: "POST",
                mode: "cors",
                headers: {
                    Origin:
                        "https://europe-west2-ciara-website-mailserver.cloudfunctions.net/",
                    Accept: "application/json",
                    "Content-Type": "application/json",
                },
                body: JSON.stringify(values),
            }
        )
            .then((res) => {
                setFormResponse(res.status === 200 ? "ok" : "ko");
                if (res.status === 200) {
                    window.scroll(0, 0);
                }
                setSubmitting(false);
            })
            .catch((error) => {
                setFormResponse("ko");
                setSubmitting(false);
            });
    };

    const attr = generateAttributes("Contact", props);

    return (
        <main {...attr()}>
            <section {...attr("section #form")}>
                <DotsPattern tags="1" />
                <DotsPattern tags="2" />
                <section {...attr("sectionContent")}>
                    <section {...attr("text")}>
                        <h1 {...attr("sectionTitle #contact")}>Contact us</h1>
                        <p {...attr("sectionParagraph #contact")}>
                            Our team will be pleased to give a demonstration of
                            the platform and provide more information about our
                            online and offline services and related
                            methodologies.
                        </p>
                    </section>
                    <Formik
                        initialValues={formInitialValues}
                        validate={validateForm}
                        onSubmit={submitForm}
                    >
                        {({ errors, touched, isSubmitting }) => (
                            <Form {...attr("form")}>
                                {formResponse === null ||
                                formResponse === "ko" ? (
                                    <>
                                        <div {...attr("textInputWrapper")}>
                                            <TextInput
                                                name="firstname"
                                                type="text"
                                                labelText="First Name"
                                                placeholder="John"
                                                onError={
                                                    errors.firstname &&
                                                    touched.firstname
                                                }
                                            />
                                        </div>
                                        <div {...attr("textInputWrapper")}>
                                            <TextInput
                                                name="lastname"
                                                type="text"
                                                labelText="Last Name"
                                                placeholder="Doe"
                                                onError={
                                                    errors.lastname &&
                                                    touched.lastname
                                                }
                                            />
                                        </div>
                                        <div
                                            {...attr("textInputWrapper #email")}
                                        >
                                            <TextInput
                                                name="emailAddress"
                                                type="email"
                                                labelText="Email address"
                                                placeholder="john.doe@example.com"
                                                onError={
                                                    errors.emailAddress &&
                                                    touched.emailAddress
                                                }
                                            />
                                        </div>
                                        <div
                                            {...attr(
                                                "textInputWrapper #company"
                                            )}
                                        >
                                            <TextInput
                                                name="company"
                                                type="text"
                                                labelText="Company"
                                                placeholder="Example"
                                                onError={
                                                    errors.company &&
                                                    touched.company
                                                }
                                            />
                                        </div>
                                        <div
                                            {...attr(
                                                "textInputWrapper #message"
                                            )}
                                        >
                                            <TextInput
                                                tags="message"
                                                variations="textarea"
                                                name="message"
                                                type="textarea"
                                                labelText="Message"
                                                placeholder="Tell us about your needs"
                                                onError={
                                                    errors.message &&
                                                    touched.message
                                                }
                                            />
                                        </div>
                                        <Button
                                            variations="type:text"
                                            type="submit"
                                            disabled={isSubmitting}
                                            text={
                                                !isSubmitting
                                                    ? `Send your message`
                                                    : "Sending..."
                                            }
                                        />
                                        {formResponse === "ko" && (
                                            <p {...attr("formErrorMessage")}>
                                                Sorry, your message could not be
                                                sent. Please, send us an email
                                                at: contact@carbone4.com
                                            </p>
                                        )}
                                    </>
                                ) : (
                                    <p {...attr("formSuccessMessage")}>
                                        Message sent successfully!
                                    </p>
                                )}
                            </Form>
                        )}
                    </Formik>
                </section>
            </section>
            <Divider variations="nextToGray:true" />
            <section {...attr("section #info")}>
                <section {...attr("infoSection")}>
                    <h2 {...attr("infoTitle")}>Location</h2>
                    <section {...attr("infoWrapper")}>
                        <section {...attr("info")}>
                            <strong {...attr("strong")}>Carbone 4</strong>
                            <br />
                            54, rue de Clichy
                            <br />
                            75009 Paris
                        </section>
                        <section {...attr("info")}>
                            <strong {...attr("strong")}>Metro: </strong>
                            ligne 2 Place de Clichy station, lignes 3, 12 and 14
                            St Lazare station, ligne 13 Liège station
                        </section>
                        <section {...attr("info")}>
                            <strong {...attr("strong")}>RER: </strong>RER E
                            Haussmann-St-Lazare station, RER A Auber station
                        </section>
                    </section>
                </section>
            </section>
        </main>
    );
}

export default Contact;
