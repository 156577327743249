import React from "react";
import generateAttributes from "../../functions/generateAttributes";
import "../../styles/components/DotsPattern.scss";
import dots from "../../assets/images/patterns/dots.svg";

function DotsPattern(props) {
    const attr = generateAttributes("DotsPattern", props);

    return (
        <div {...attr()}>
            <img
                {...attr("image")}
                src={dots}
                alt="Pattern representing dots"
                width="1034"
                height="1034"
                draggable="false"
            />
        </div>
    );
}

export default DotsPattern;
