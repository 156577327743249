import React, { useState, useEffect } from "react";
// prettier-ignore
import { Switch, Route, useLocation } from "react-router-dom";
import generateAttributes from "../functions/generateAttributes";
import "../styles/App.scss";
import Menu from "./components/Menu/Menu.js";
import Home from "./pages/Home/Home.js";
import About from "./pages/About.js";
import Contact from "./pages/Contact.js";
import Footer from "./components/Footer.js";

function App(props) {
    const location = useLocation();

    // Prevents all transition on media queries change
    const [resizing, setResizing] = useState(false);
    useEffect(() => {
        window.addEventListener("resize", () => {
            setResizing(true);
            setTimeout(() => setResizing(false), 200);
        });
    }, []);
    const attr = generateAttributes("App", props, { resizing });

    return (
        <div {...attr()}>
            <Menu
                variations={location.pathname === "/" ? "overContent" : null}
            />
            <Switch>
                <Route path="/about">
                    <About />
                </Route>
                <Route path="/contact">
                    <Contact />
                </Route>
                <Route path="/">
                    <Home />
                </Route>
            </Switch>
            <Footer />
        </div>
    );
}

export default App;
