import React from "react";
import generateAttributes from "../../../functions/generateAttributes";
import "../../../styles/components/Menu/MenuButton.scss";
import Icon from "./../../components/Icon.js";
import { ReactComponent as BurgerBars } from "../../../assets/icons/burgerBars.svg";
import { ReactComponent as Cross } from "../../../assets/icons/cross.svg";

function MenuButton(props) {
    const attr = generateAttributes("MenuButton", props, {
        open: props.open,
        overContent: props.overContent,
    });

    return (
        <button
            {...attr()}
            onMouseDown={(e) => e.preventDefault()} // Prevents focus styles on click
            onClick={(e) => {
                e.preventDefault(); // Prevents zooming behaviour in Safari iOS
                props.setOpen(!props.open);
            }}
            tabIndex={!props.focusable ? "-1" : ""}
        >
            <span data-visually-hidden>
                {!props.open ? "Open the menu" : "Close the menu"}
            </span>
            {!props.open ? (
                <Icon variations="fixedSize" svg={BurgerBars} />
            ) : (
                <Icon variations="fixedSize" svg={Cross} />
            )}
        </button>
    );
}

export default MenuButton;
