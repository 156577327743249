import React from "react";
import generateAttributes from "../../functions/generateAttributes";
import "../../styles/components/Footer.scss";
import Button from "./../components/Button.js";
import Icon from "./../components/Icon.js";
import CustomLink from "./../components/CustomLink.js";
import { ReactComponent as Twitter } from "../../assets/icons/twitter.svg";
import { ReactComponent as LinkedIn } from "../../assets/icons/linkedin.svg";
import { ReactComponent as Facebook } from "../../assets/icons/facebook.svg";
import { ReactComponent as Youtube } from "../../assets/icons/youtube.svg";

function Footer(props) {
    const attr = generateAttributes("Footer", props);

    return (
        <footer {...attr()}>
            <div {...attr("wrapper")}>
                <section {...attr("section")}>
                    <h3 {...attr("sectionTitle")}>Site map</h3>
                    <ul {...attr("links")}>
                        <li>
                            <CustomLink type="internal" to="/" text="Home" />
                        </li>
                        <li>
                            <CustomLink
                                type="internal"
                                to="/about"
                                text="About us"
                            />
                        </li>
                        <li>
                            <CustomLink
                                type="internal"
                                to="/contact"
                                text="Contact us"
                            />
                        </li>
                    </ul>
                </section>
                <section {...attr("section")}>
                    <h3 {...attr("sectionTitle")}>Company</h3>
                    <p {...attr("companyDescription")}>
                        CIARA is a platform created by{" "}
                        <CustomLink
                            type="external"
                            to="http://www.carbone4.com/"
                            text="Carbone&nbsp;4"
                        />
                        , the leading consulting firm specialized in low-carbon
                        strategy and climate resilience.
                    </p>
                </section>
                <section {...attr("section #contact")}>
                    <section {...attr("social")}>
                        <Button
                            variations="type:icon inFooter"
                            type="externalLink"
                            href="https://twitter.com/Carbone4"
                            hiddenLabel="Link to Carbone 4 Twitter account"
                            icon={<Icon variations="fixedSize" svg={Twitter} />}
                        />
                        <Button
                            variations="type:icon inFooter"
                            type="externalLink"
                            href="https://fr.linkedin.com/company/carbone-4"
                            hiddenLabel="Link to Carbone 4 LinkedIn account"
                            icon={
                                <Icon variations="fixedSize" svg={LinkedIn} />
                            }
                        />
                        <Button
                            variations="type:icon inFooter"
                            type="externalLink"
                            href="https://www.facebook.com/fr.carbone4"
                            hiddenLabel="Link to Carbone 4 Facebook page"
                            icon={
                                <Icon variations="fixedSize" svg={Facebook} />
                            }
                        />
                        <Button
                            variations="type:icon inFooter"
                            type="externalLink"
                            hiddenLabel="Link to Carbone 4 Youtube channel"
                            href="https://www.youtube.com/channel/UCe_-wer152c3JpT_YaDP9gw"
                            icon={<Icon variations="fixedSize" svg={Youtube} />}
                        />
                    </section>
                    <section {...attr("address")}>
                        <strong {...attr("companyName")}>Carbone 4</strong>
                        54, rue de Clichy
                        <br /> 75009 Paris
                    </section>
                </section>
                <section {...attr("copyright")}>
                    Copyright © 2020 Carbone 4
                </section>
            </div>
        </footer>
    );
}

export default Footer;
