export default function generateAttributes(componentName, props, states) {
    const generateTagsString = (tags) => {
        const tagsArray = tags.split(" ");
        return `#${tagsArray.join(" #")}`;
    };

    const generateVariationsString = (variations) => {
        const variationsArray = variations.split(" ");
        return `_${variationsArray.join(" _")}`;
    };

    const generateStatesString = (states) => {
        if (typeof states == "object" && !Array.isArray(states)) {
            return Object.entries(states)
                .map((state) =>
                    state[1] !== undefined ? `§${state[0]}:${state[1]}` : ""
                )
                .join(" ");
        } else {
            throw new Error(
                "generateAttributes() expects an object for the 'states' key."
            );
        }
    };

    const tagsString = props?.tags ? ` ${generateTagsString(props?.tags)}` : "";
    const variationsString = props?.variations
        ? ` ${generateVariationsString(props?.variations)}`
        : "";
    const stateString = states ? ` ${generateStatesString(states)}` : "";

    return (elementName) => ({
        // prettier-ignore
        className: `${componentName}_${elementName ?? "root"}${tagsString}${variationsString}${stateString}`,
        base: "",
    });
}
