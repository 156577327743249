import React, { useState } from "react";
import { NavLink } from "react-router-dom";
import generateAttributes from "../../../functions/generateAttributes";
import "../../../styles/components/Menu/MenuItem.scss";

function MenuItem(props) {
    const [active, setActive] = useState(false);
    const attr = generateAttributes("MenuItem", props, {
        active,
        menuOpen: props.menuOpen,
        menuTopOfPage: props.menuTopOfPage,
    });

    return (
        <NavLink
            {...attr()}
            to={props.to}
            isActive={(match) => setActive(match ? true : false)}
            exact
            tabIndex={props.focusable === false ? "-1" : ""}
            onMouseDown={(e) => e.preventDefault()} // Prevents focus styles on click
        >
            {props.text}
        </NavLink>
    );
}

export default MenuItem;
