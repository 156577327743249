import React, { useRef, useState, useEffect } from "react";
import generateAttributes from "../../functions/generateAttributes";
import "../../styles/pages/About.scss";
import Divider from "../components/Divider.js";
import CustomLink from "../components/CustomLink.js";
import DotsPattern from "../components/DotsPattern.js";

import carbone4Logo from "../../assets/images/logos/logo_carbone4@x2.png";
import AFDLogo from "../../assets/images/logos/logo_afd@x2.png";
import banquePostaleLogo from "../../assets/images/logos/logo_la-banque-postale@x2.png";
import climateKICLogo from "../../assets/images/logos/logo_climate-kic@x2.png";
import meridiamLogo from "../../assets/images/logos/logo_meridiam@x2.png";
import generalliLogo from "../../assets/images/logos/logo_generalli@x2.png";

function About(props) {
    const firstSponsorsElement = useRef(null);
    const primeSponsorsElement = useRef(null);
    const [firstSponsorVisible, setFirstSponsorVisible] = useState(false);
    const [primeSponsorVisible, setPrimeSponsorVisible] = useState(false);
    useEffect(() => {
        const firstSponsorsEl = firstSponsorsElement.current;
        const primeSponsorsEl = primeSponsorsElement.current;

        function callback(entries) {
            for (const entry of entries) {
                if (entry.isIntersecting) {
                    if (entry.target === firstSponsorsEl) {
                        setFirstSponsorVisible(true);
                    } else {
                        setPrimeSponsorVisible(true);
                    }
                }
            }
        }

        // Set up intersection observers for elements
        const myIntersectionObserver = new IntersectionObserver(callback, {
            root: null, // observe the viewport
            rootMargin: "0px 0px -12px 0px",
            threshold: 1,
        });
        myIntersectionObserver.observe(firstSponsorsEl);
        myIntersectionObserver.observe(primeSponsorsEl);

        // Remove the intersection observers
        return () => {
            myIntersectionObserver.unobserve(firstSponsorsEl);
            myIntersectionObserver.unobserve(primeSponsorsEl);
        };
    }, []);

    const attr = generateAttributes("About", props, {
        firstSponsorVisible,
        primeSponsorVisible,
    });

    return (
        <main {...attr()}>
            <h1 data-visually-hidden>Learn about our company</h1>
            <section {...attr("section #company")}>
                <DotsPattern />
                <section {...attr("sectionMainInformation #company")}>
                    <h2 {...attr("sectionTitle #company")}>Our company</h2>
                    <section {...attr("content")}>
                        <section {...attr("text")}>
                            <p {...attr("sectionParagraph #company")}>
                                CIARA is a platform created by{" "}
                                <CustomLink
                                    type="external"
                                    to="http://www.carbone4.com/"
                                    text="Carbone&nbsp;4"
                                />
                                , the leading consulting firm on the challenges
                                of energy and climate transition. We work to
                                turn our customers into leaders in the climate
                                challenge.
                            </p>
                            <p {...attr("sectionParagraph #company")}>
                                Learn more:{" "}
                                <CustomLink
                                    type="external"
                                    to="http://www.carbone4.com/"
                                    text=" www.carbone4.com"
                                />
                            </p>
                        </section>
                        <img
                            {...attr("logo")}
                            src={carbone4Logo}
                            alt="Logo of Carbone 4"
                            width="496"
                            height="160"
                            draggable="false"
                        />
                    </section>
                </section>
            </section>
            <Divider variations="nextToGray:true" />
            <section {...attr("section #methodologies")}>
                <div {...attr("grid")}>
                    <section {...attr("sectionMainInformation")}>
                        <h2 {...attr("sectionTitle")}>Our methodologies</h2>
                        <section {...attr("text")}>
                            <p {...attr("sectionParagraph")}>
                                Carbone 4 has been developing
                                infrastructure-specific methodologies for more
                                than 10 years. Recent methodological
                                developments have received support from the{" "}
                                <strong {...attr("strong")}>
                                    2-infra challenge initiative
                                </strong>{" "}
                                gathering top financial players in
                                infrastructure.
                            </p>
                            <p {...attr("sectionParagraph")}>
                                This initiative aimed at developing new KPIs
                                regarding the alignment of infrastructure
                                portfolios with a 2-degree trajectory and the
                                climate-related risks to which the assets are
                                subjected.
                            </p>
                        </section>
                    </section>
                    <section {...attr("sponsors")}>
                        <header {...attr("sponsorsHeader")}>
                            2-infra challenge
                        </header>
                        <section {...attr("sponsorsGroups")}>
                            <section
                                {...attr("sponsorGroup #first")}
                                ref={firstSponsorsElement}
                            >
                                <header {...attr("sponsorGroupHeader")}>
                                    First & Prime sponsors
                                </header>
                                <section {...attr("sponsorsLogos")}>
                                    <img
                                        {...attr("sponsorLogo #AFD")}
                                        src={AFDLogo}
                                        alt="Logo of AFD"
                                        width="154"
                                        height="150"
                                        draggable="false"
                                    />
                                    <img
                                        {...attr("sponsorLogo #banquePostale")}
                                        src={banquePostaleLogo}
                                        alt="Logo of La Banque Postale"
                                        width="124"
                                        height="178"
                                        draggable="false"
                                    />
                                    <img
                                        {...attr("sponsorLogo #climateKIC")}
                                        src={climateKICLogo}
                                        alt="Logo of Climate-KIC"
                                        width="352"
                                        height="130"
                                        draggable="false"
                                    />
                                    <img
                                        {...attr("sponsorLogo #meridiam")}
                                        src={meridiamLogo}
                                        alt="Logo of Meridiam"
                                        width="272"
                                        height="142"
                                        draggable="false"
                                    />
                                </section>
                            </section>
                            <section
                                {...attr("sponsorGroup #prime")}
                                ref={primeSponsorsElement}
                            >
                                <header {...attr("sponsorGroupHeader")}>
                                    Prime sponsor
                                </header>
                                <section {...attr("sponsorsLogos #prime")}>
                                    <img
                                        {...attr("sponsorLogo #generalli")}
                                        src={generalliLogo}
                                        alt="Logo of Generalli Global Infrastructure"
                                        draggable="false"
                                        width="388"
                                        height="30"
                                    />
                                </section>
                            </section>
                        </section>
                    </section>
                </div>
            </section>
        </main>
    );
}

export default About;
