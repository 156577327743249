import React from "react";
import generateAttributes from "../../functions/generateAttributes";
import "../../styles/components/Icon.scss";

function Icon(props) {
    const attr = generateAttributes("Icon", props);

    return (
        <div {...attr()}>
            <props.svg />
        </div>
    );
}

export default Icon;
