import React from "react";
import { Link } from "react-router-dom";
import generateAttributes from "../../functions/generateAttributes";
import "../../styles/components/CustomLink.scss";

function CustomLink(props) {
    const attr = generateAttributes("CustomLink", props);

    if (props.type === "internal") {
        return (
            <Link
                {...attr()}
                to={props.to}
                tabIndex={props.focusable === false ? "-1" : ""}
                onMouseDown={(e) => e.preventDefault()} // Prevents focus styles on click
            >
                {props.text}
            </Link>
        );
    }

    if (props.type === "external") {
        return (
            <a
                {...attr()}
                href={props.to}
                tabIndex={props.focusable === false ? "-1" : ""}
                onMouseDown={(e) => e.preventDefault()} // Prevents focus styles on click
            >
                {props.text}
            </a>
        );
    }
}

export default CustomLink;
