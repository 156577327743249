import React, { useRef, useState, useEffect } from "react";
import generateAttributes from "../../../functions/generateAttributes";
import "../../../styles/pages/Home/CoverageCategory.scss";

function CoverageCategory(props) {
    const rootElement = useRef(null);
    const [visible, setVisible] = useState(false);
    useEffect(() => {
        const rootEl = rootElement.current;
        function callback(entries) {
            for (const entry of entries) {
                if (entry.isIntersecting) {
                    setVisible(true);
                    // Only call setFirstElementVisible if it is passed
                    // i.e. if this is the first CoverageCategory
                    if (props.setFirstElementVisible) {
                        props.setFirstElementVisible();
                    }
                }
            }
        }

        let myIntersectionObserver;
        // If we are on desktop or this is the first MetricCard
        if (
            window.matchMedia("(min-width: 800px)").matches ||
            props.setFirstElementVisible
        ) {
            // Set up an intersection observer for the rootElement
            myIntersectionObserver = new IntersectionObserver(callback, {
                root: null, // observe the viewport
                rootMargin: "0px 0px -12px 0px",
                // Doesn't work with 1 in Safari desktop at high res...
                threshold: 0.99,
            });
            myIntersectionObserver.observe(rootEl);
        }

        // Remove the intersection observers
        return () => {
            if (myIntersectionObserver) {
                myIntersectionObserver.unobserve(rootEl);
            }
        };
    }, [props]);

    const attr = generateAttributes("CoverageCategory", props, {
        // If we are on desktop, use the visible state, else use the firstElementVisible prop
        visible: window.matchMedia("(min-width: 800px)").matches
            ? visible
            : props.firstElementVisible,
    });

    return (
        <div {...attr()} ref={rootElement}>
            <div {...attr("illustration")}>{props.illustration}</div>
            <div {...attr("categoryTitle")}>{props.title}</div>
        </div>
    );
}

export default CoverageCategory;
