import React from "react";
import { Field, ErrorMessage } from "formik";
import generateAttributes from "../../functions/generateAttributes";
import "../../styles/components/TextInput.scss";

function TextInput(props) {
    const attr = generateAttributes("TextInput", props, {
        onError: props.onError,
    });

    return (
        <>
            <label {...attr("label")} htmlFor={props.name}>
                {props.labelText}
            </label>
            <Field
                {...attr("field")}
                type={props.type !== "textarea" ? props.type : null}
                component={props.type === "textarea" ? "textarea" : null}
                id={props.name}
                name={props.name}
                placeholder={props.placeholder}
            />
            <ErrorMessage
                {...attr("errorMessage")}
                name={props.name}
                component="div"
            />
        </>
    );
}

export default TextInput;
