import React, { useRef, useState, useEffect } from "react";
import generateAttributes from "../../../functions/generateAttributes";
import "../../../styles/pages/Home/Feature.scss";
import Icon from "../../components/Icon.js";

function Feature(props) {
    const rootElement = useRef(null);
    const [visible, setVisible] = useState(false);
    useEffect(() => {
        const rootEl = rootElement.current;
        function callback(entries) {
            for (const entry of entries) {
                if (entry.isIntersecting) {
                    setVisible(true);
                }
            }
        }

        // Set up intersection observers for all tab contents
        const myIntersectionObserver = new IntersectionObserver(callback, {
            root: null, // observe the viewport
            rootMargin: "0px 0px -12px 0px",
            threshold: 1,
        });
        myIntersectionObserver.observe(rootEl);

        // Remove the intersection observers
        return () => {
            myIntersectionObserver.unobserve(rootEl);
        };
    }, []);

    const attr = generateAttributes("Feature", props, { visible });

    return (
        <div {...attr()} ref={rootElement}>
            <div {...attr("iconWrapper")}>
                <Icon variations="fixedSize" svg={props.icon} />
            </div>
            <section {...attr("textWrapper")}>
                <h3 {...attr("title")}>{props.title}</h3>
                <p {...attr("content")}>{props.content}</p>
            </section>
        </div>
    );
}

export default Feature;
