import React, { useRef, useState, useEffect } from "react";
import generateAttributes from "../../../functions/generateAttributes";
import "../../../styles/pages/Home/Home.scss";
import Feature from "./Feature.js";
import CoverageCategory from "./CoverageCategory.js";
import MetricsSection from "./MetricsSection.js";
import VisuSlider from "./VisuSlider.js";
import Button from "../../components/Button.js";
import Divider from "../../components/Divider.js";
import Icon from "../../components/Icon.js";
import DotsPattern from "../../components/DotsPattern.js";
import { ReactComponent as ChevronBottomSvg } from "../../../assets/icons/chevronBottom.svg";
import { ReactComponent as Document } from "../../../assets/icons/document.svg";
import { ReactComponent as ConvergingArrows } from "../../../assets/icons/convergingArrows.svg";
import { ReactComponent as Check } from "../../../assets/icons/check.svg";
import { ReactComponent as AlertTriangle } from "../../../assets/icons/alertTriangle.svg";
import { ReactComponent as BarChart } from "../../../assets/icons/barChart.svg";
import { ReactComponent as Mobilities } from "../../../assets/images/illustrations/mobilities.svg";
import { ReactComponent as Energies } from "../../../assets/images/illustrations/energies.svg";
import { ReactComponent as WasteAndWater } from "../../../assets/images/illustrations/wasteAndWater.svg";
import { ReactComponent as ITAndTeriary } from "../../../assets/images/illustrations/ITAndTertiary.svg";

import heroMobileJpg from "../../../assets/images/hero/hero_mobile_opt.jpg";
import heroMobileWebp from "../../../assets/images/hero/hero_mobile_opt.webp";
import heroMobileAvif from "../../../assets/images/hero/hero_mobile_opt.avif";
import heroTabletJpg from "../../../assets/images/hero/hero_tablet_opt.jpg";
import heroTabletWebp from "../../../assets/images/hero/hero_tablet_opt.webp";
import heroTabletAvif from "../../../assets/images/hero/hero_tablet_opt.avif";
import heroDesktopJpg from "../../../assets/images/hero/hero_desktop_opt.jpg";
import heroDesktopWebp from "../../../assets/images/hero/hero_desktop_opt.webp";
import heroDesktopAvif from "../../../assets/images/hero/hero_desktop_opt.avif";

const featuresData = [
    {
        icon: Document,
        title: "Report current and forward-looking climate performance",
        content:
            "Assess the carbon impact of your assets and portfolio using carbon footprint and avoided emissions.",
    },
    {
        icon: ConvergingArrows,
        title: "Align your portfolio with the Paris Agreement",
        content:
            "Assess the temperature of your portfolio ranking from 1,5°C to 6°C scenario and comply with the Paris Agreement.",
    },
    {
        icon: Check,
        title: "Comply with ESG standards and labels",
        content:
            "Assess the green share of portfolio (EU taxonomy) and report all KPIs for labels and investors' questionnaires (PRI, Greenfin, Label SRI...).",
    },
    {
        icon: AlertTriangle,
        title: "Assess climate-related physical and transition risks",
        content:
            "Apprehend the exposure of assets and portfolio to climate-related physical and transition risks through the assessment of key hazards, an overall vulnerability score and the financial value at risk.",
    },
    {
        icon: BarChart,
        title: "Monitor your climate performance over time",
        content:
            "Implement an action plan and track progress of assets and portfolio over time with a dedicated climate dashboard.",
    },
];

function Home(props) {
    // Handle logic for Discover button
    const headerBottomBar = useRef(null);
    function scrollToEndOfHeader() {
        headerBottomBar.current.scrollIntoView({
            block: "start",
            behavior: "smooth",
        });
    }

    // Animates the header on scroll
    const imageElement = useRef(null);
    const overlayElement = useRef(null);
    useEffect(() => {
        const mqDesktop = window.matchMedia("(min-width: 800px)");

        const callback = () => {
            const scrollOffset = window.pageYOffset;

            // Hide hero image if scrolled past so that it doesn't appear below
            // the content on iOS
            if (scrollOffset < 2000) {
                imageElement.current.style.opacity = 1;
            } else {
                imageElement.current.style.opacity = 0;
            }

            // Only do this if the user has scrolled less than 1000px
            // and if WAAPI animate() is supported in the browser
            if (scrollOffset < 1000 && "animate" in imageElement.current) {
                // Only animate on scroll on desktop, perf on mobile are not good enough
                if (mqDesktop.matches) {
                    imageElement.current.animate(
                        [
                            {
                                // prettier-ignore
                                transform: `translateY(${scrollOffset / -8}px) rotateZ(0)`,
                            },
                        ],
                        {
                            // timing options
                            duration: 5,
                            easing: "linear",
                            fill: "forwards",
                        }
                    );
                }

                overlayElement.current.animate(
                    [
                        {
                            opacity: scrollOffset / 1000,
                        },
                    ],
                    {
                        // timing options
                        duration: 1,
                        easing: "linear",
                        fill: "both",
                    }
                );
            }
        };

        window.addEventListener("scroll", callback);

        return () => {
            window.removeEventListener("scroll", callback);
        };
    }, []);

    // Handle CoverageCategories visibility
    // State used on mobile to set all CoverageCategories to visible once the first one is
    const [firstElementVisible, setFirstElementVisible] = useState(false);

    const attr = generateAttributes("Home", props);

    const metricCards = {
        climate: [
            {
                title: "Carbon footprint",
                content: [
                    "tCO<span class='subscript'>₂</span>e/asset",
                    "tCO<span class='subscript'>₂</span>e/€M invested or financed",
                    "tCO<span class='subscript'>₂</span>e/€M revenues",
                ],
                coverage: ["world"],
                access: ["online", "offline"],
            },
            {
                title: "Green share",
                content: [
                    "Green share (EU Taxonomy)",
                    "Labels (Greenfin, SRI...)",
                ],
                coverage: ["world"],
                access: ["online", "offline"],
            },
            {
                title: "Portfolio T°C alignment",
                content: [
                    "Portfolio performance compared to 2°C scenario",
                    "Portfolio global warming TºC (1,5°C to 6°C benchmark)",
                ],
                coverage: ["Europe", "Med. Rim", "North Am."],
                access: ["online", "offline"],
            },
        ],
        business: [
            {
                title: "Physical risks score",
                content: [
                    "Key hazards per asset",
                    "Score at asset and portfolio level",
                ],
                coverage: ["Europe", "Med. Rim", "North Am."],
                access: ["offline"],
            },
            {
                title: "Transition risks score",
                content: [
                    "Key hazards per asset",
                    "Score at asset and portfolio level",
                ],
                coverage: ["Europe", "Med. Rim", "North Am."],
                access: ["offline"],
            },
        ],
    };

    return (
        <>
            <header {...attr("header")}>
                <div {...attr("overlay")} ref={overlayElement} />
                <picture {...attr("imageWrapper")}>
                    <source
                        srcSet={heroDesktopAvif}
                        type="image/avif"
                        media="(min-width: 1000px)"
                    />
                    <source
                        srcSet={heroDesktopWebp}
                        type="image/webp"
                        media="(min-width: 1000px)"
                    />
                    <source
                        srcSet={heroDesktopJpg}
                        type="image/jpeg"
                        media="(min-width: 1000px)"
                    />
                    <source
                        srcSet={heroTabletAvif}
                        type="image/avif"
                        media="(min-width: 500px)"
                    />
                    <source
                        srcSet={heroTabletWebp}
                        type="image/webp"
                        media="(min-width: 500px)"
                    />
                    <source
                        srcSet={heroTabletJpg}
                        type="image/jpeg"
                        media="(min-width: 500px)"
                    />
                    <source srcSet={heroMobileAvif} type="image/avif" />
                    <source srcSet={heroMobileWebp} type="image/webp" />
                    <img
                        {...attr("image")}
                        ref={imageElement}
                        src={heroMobileJpg}
                        alt="A bridge illustrating the idea of infrastructure"
                        width="750"
                        height="1624"
                        draggable="false"
                    />
                </picture>
                <section {...attr("content")}>
                    <div {...attr("titleContainer")}>
                        <h1 {...attr("title")}>Ciara</h1>
                        <span {...attr("legend")}>
                            Climate Impact Analytics for Real Assets
                        </span>
                    </div>
                    <p {...attr("subTitle")}>
                        A single platform gathering all climate metrics designed
                        for top financial players in infrastructure
                    </p>
                    <Button
                        variations="style:outlined type:text size:big"
                        type="button"
                        text="Discover"
                        hiddenLabel="Scroll to the next section"
                        onClick={scrollToEndOfHeader}
                    />
                </section>
                <div {...attr("bottomBar")} ref={headerBottomBar}>
                    <Icon variations="fixedSize" svg={ChevronBottomSvg} />
                </div>
            </header>
            <main {...attr("main")}>
                <section {...attr("section #solution")}>
                    <DotsPattern tags="solution" />
                    <section {...attr("sectionContent")}>
                        <section {...attr("sectionMainInformation")}>
                            <div {...attr("sectionSupTitle")}>Custom-made</div>
                            <h2 {...attr("sectionTitle")}>
                                Our solutions for investors and asset managers
                            </h2>
                            <p {...attr("sectionParagraph")}>
                                We provide A-level metrics and expertise to
                                assess and improve your portfolio climate impact
                                and business resilience. Our tailor-made
                                solutions are adapted to your specific needs,
                                your ESG policy and your investment strategy
                                (funds, fund of funds, capital or debt
                                investment).
                            </p>
                        </section>
                        <section {...attr("features")}>
                            {featuresData.map((feature, index) => (
                                <Feature
                                    key={index}
                                    icon={feature.icon}
                                    title={feature.title}
                                    content={feature.content}
                                />
                            ))}
                        </section>
                    </section>
                </section>
                <Divider />
                <section {...attr("section #coverage")}>
                    <DotsPattern tags="coverage" />
                    <section {...attr("sectionMainInformation #coverage")}>
                        <div {...attr("sectionSupTitle #center")}>
                            Wide coverage
                        </div>
                        <h2 {...attr("sectionTitle #center")}>
                            We cover more than 60 specific infrastructure asset
                            types
                        </h2>
                        <p {...attr("sectionParagraph #center")}>
                            Our bottom-up methodology is tailored for each asset
                            type. Our data collection process is adapted to your
                            needs and is designed to collect a limited amount of
                            data per asset. Our team provides guidance to assist
                            you every step of the way.
                        </p>
                    </section>
                    <section {...attr("categoriesWrapper")}>
                        <div {...attr("categories")}>
                            <CoverageCategory
                                title="Mobility"
                                illustration={<Mobilities />}
                                index={0}
                                setFirstElementVisible={setFirstElementVisible}
                                firstElementVisible={firstElementVisible}
                            />
                            <CoverageCategory
                                title="Energy"
                                illustration={<Energies />}
                                index={1}
                                firstElementVisible={firstElementVisible}
                            />
                            <CoverageCategory
                                title="Waste & Water"
                                illustration={<WasteAndWater />}
                                firstElementVisible={firstElementVisible}
                            />
                            <CoverageCategory
                                title="IT & Tertiary"
                                illustration={<ITAndTeriary />}
                                firstElementVisible={firstElementVisible}
                            />
                        </div>
                    </section>
                </section>
                <Divider variations="nextToGray:true" />
                <MetricsSection metricCards={metricCards} />
                <Divider variations="nextToGray:true" />
                <section {...attr("section #visualisation")}>
                    <DotsPattern tags="visualisation" />
                    <section {...attr("sectionMainInformation #visualisation")}>
                        <div {...attr("sectionSupTitle #center")}>
                            Visual representation
                        </div>
                        <h2 {...attr("sectionTitle #center")}>
                            Your tailored climate dashboard
                        </h2>
                        <p {...attr("sectionParagraph #center")}>
                            Gathering all the KPIs you need in one single
                            dashboard, allowing your team to extract
                            information, assess the climate performance of
                            portfolio over lifetime, report to labels and
                            questionnaires. A-level visuals will be proposed to
                            feed your strategy and communication with key
                            stakeholders.
                        </p>
                    </section>
                    <VisuSlider />
                </section>
                <Divider />
                <section {...attr("section #contact")}>
                    <section {...attr("contactText")}>
                        <h3 {...attr("contactSectionTitle")}>
                            Contact us for more information
                        </h3>
                        <p {...attr("sectionParagraph #center #contact")}>
                            Our team will be pleased to give a demonstration of
                            the platform and provide more information about our
                            online and offline services and related
                            methodologies.
                        </p>
                    </section>
                    <Button
                        tags="contact"
                        variations="type:text size:big"
                        type="link"
                        to="/contact"
                        text="Send us a message"
                        hiddenLabel="Go to contact page"
                    />
                </section>
            </main>
        </>
    );
}

export default Home;
