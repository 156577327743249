import React from "react";
import generateAttributes from "../../../functions/generateAttributes";
import "../../../styles/pages/Home/Tab.scss";

const Tab = React.forwardRef((props, ref) => {
    const attr = generateAttributes("Tab", props, {
        selected: props.selected,
    });

    return (
        <li {...attr()}>
            <button
                {...attr("button")}
                ref={ref}
                onMouseDown={(e) => e.preventDefault()} // Prevents focus styles on click
                onClick={() => {
                    props.setSelectedTab(props.tabId);
                }}
                tabIndex={props.variations === "inClipped" ? -1 : ""}
            >
                {props.tabName}
            </button>
        </li>
    );
});

export default Tab;
